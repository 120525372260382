<template>
    <i class="audio-toggle" :class="{'white-background': whiteBackground && audioExist}">
        <img :style="{opacity: audioExist ? '1' :  '0'}"
            :class="{'audio-playing': isAudioPlaying}"
         :src="audioIcon" @click="playAudio">
    </i>
</template>
<script>
import AudioService from '@/services/AudioService.js'

export default {
  name: 'AudioToggle',
    props:{
        audio: Object, 
        'white-background': {
            type: Boolean, 
            required: false, 
            default: false
        }
    },
    components:{
        AudioService
    },
    data(){
        return{
            url: '',
            audioExist: false, 
            isAudioPlaying: false
        }
    },
    computed:{
        audioIcon(){
            return require('@/assets/icons/ic_audio.svg')
        }, 
        source(){
            return  new Audio(this.url)
        }, 
        audioPlaying(){
            return this.$store.state.audioPlaying
        }
    },
    methods:{
        playAudio(){
            if(!this.audioExist)
                return
            this.$store.commit('updateAudioPlaying', this.audio.id)    
            this.isAudioPlaying = !this.isAudioPlaying
            this.source.paused
                ? this.source.play()
                : this.source.pause()
        }, 
    }, 
    beforeDestroy(){
        if(!this.source.paused)
            this.source.pause()
    }, 
    mounted(){
        let audioName = this.locale != 'es'
            ? this.audio.nameEnglish
            : this.audio.nameSpanish
        if(!audioName)
            return    
        this.url = `${process.env.VUE_APP_CDN}audios/${audioName}`;
        this.audioExist = true
    },
    watch:{
        audioPlaying(value){
            if(value != this.audio.id){
                this.source.pause()
                this.isAudioPlaying = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
.audio-toggle{
    max-width: 24px;
    max-height: 24px;
    audio{
        display: none;
    }
    &.white-background{
        background-color: #fff;
        border-radius: 50%;
    }
}
img{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    cursor: pointer;
}

.audio-playing{
    background-color: rgba(129,199,132, 0.6);

}

</style>