import apiClient from '@/services/ApiClient.js'

export default {
    uploadImage(image) {
        return apiClient.post('images', image,{
            headers: {
                'Content-Type': 'multipart/form-data'
            } })
    },
    async getOriginalName(imageId) {
        return apiClient.get(`images/${imageId}/name`)
    },
    async updateImageInfo(imageId, info) {
        return apiClient.patch(`images/${imageId}/info`, info)
    }
}