<template>
    <div class="management-dashboard-bar-container">
        <div class="sections-container row">
            <div class="management-path">
                <span>{{`${$t(sectionPath.parent.toLowerCase())} > ${$t(sectionPath.child.toLowerCase())}`}}</span>
            </div>
            <div class="sections-menu">
                <label for="section">
                    {{`${withCapitalLetter($t("sections"))}:`}}</label>
                <select name="section" v-model="selectedSection">
                    <option v-for="(section, index) in sections" 
                            :key="index" 
                            :value="section.aka">
                        {{withCapitalLetter($t(section.name))}}</option>
                </select>
            </div>  
            <div class="properties-panel-header">

            </div>
        </div>
    </div>    
</template>

<script>

import AuthenticationService from "@/services/AuthenticationService.js";
import { Role } from "@/utils/Roles.js";
import StringUtils from "@/utils/StringUtils.js";

export default {
    name: "ManagementDashboardBar",
    components: {
        StringUtils
    },
    computed: {
        sectionPath(){
            return this.$store.state.managementDashboardNavBarPath;
        },
        userRole(){
            return AuthenticationService.getProfile().roleName.replace(/\s/g, "");
        },
        sections(){
            return Role[this.userRole].toManageAllowedSections ?
                        Role[this.userRole].toManageAllowedSections() : [];
        },
        selectedSection: {
            get(){
                return this.$store.state.currentManagementSection;
            },
            set(value){
                this.$store.commit("setManagmentCurrentSection", value);
            }
        }
    },
    methods: {
        withCapitalLetter(word){
            return StringUtils.withCapitalLetter(word);
        }
    }
}
</script>

<style lang="less" scoped>

.management-dashboard-bar-container{
    width: 100%;
    box-shadow: 0 4px 10px 0 rgba(58,77,253,0.2),  inset 0 4px 10px 0 rgba(58,77,253,0.2);
	height: 48px;
    background-color: #FFFFFF;

    .sections-container{
        width: 968px;
        margin: 0px auto;

        .management-path{
            width: 100%;
            max-width: 264px;
            padding: 16px 0px;

            span{
                text-transform: uppercase;
                color: #0A1554;
                font-family: 'Roboto', sans-serif;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 16px;
            }
        }

        .sections-menu{
            width: 100%;
            text-align: center;

            label, select{
                display: inline-block;
                margin-top: 12px;

                color: rgba(0,0,0,0.54);
                font-family: Roboto;
                font-size: 16px;
            }

            label{
                font-weight: bold;
            }

            select{
                width: 200px;
                margin-left: 10px;
            }
        }

        .properties-panel-header{
            min-width: 264px;
        }
    }
}

</style>

