import apiClient from '@/services/ApiClient.js'

export default {
    async getLessonComponents(lessonId) {
        return apiClient.get(`lesson/${lessonId}/backup`)
    },
    async createLessonComponent(lessonComponent) {
        return apiClient.post('paragraph', lessonComponent)
    },
    async updateLessonComponent(lessonComponent) {
        return apiClient.put(`paragraph/${lessonComponent.id}`, lessonComponent)
    }, 
    async reoderLessonComponent(lessonComponent) {
        return apiClient.patch(`paragraph/${lessonComponent.id}`, {order:lessonComponent.order})
    },
    async removeLessonComponent(lessonComponent) {
        return apiClient.delete(`paragraph/${lessonComponent.id}`, lessonComponent)
    }, 
    async updateLessonBanner(lessonBanner) {
        return apiClient.put(`lesson/${lessonBanner.id}`, lessonBanner)
    }, 

    async saveLessonOverviewChanges(lesson){
        return apiClient.put(`lesson/${lesson.id}/overview`, lesson)
    },

    async publishLessonChanges(lessonId) {
        return apiClient.patch(`lesson/${lessonId}/publish`)
    },

    async reoderRoutePoint(point) {
        return apiClient.patch(`route/${point.id}/order`, {number:point.number})
    },
    async addRoutePoint(paragraph) {
        return apiClient.post(`paragraph/${paragraph.id}/route`)
    },
    async removeRoutePoint(point) {
        return apiClient.delete(`route/${point.id}`)
    },
    async updateRoutePoint(point) {
        return apiClient.put(`route/${point.id}`, point)
    },

}