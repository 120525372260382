<template>
  <div class="switch-button-control flex-container">
    <div class="switch-button" :class="{ enabled: enabled }" @click="toggle">
      <span class="inactive-tag">{{inactiveTag}}</span>
      <div class="button flex-container"><span class="active-tag">{{activeTag}}</span></div>
    </div>
  </div>
</template>
<script>
export default {
    name:'SwitchButton', 
    props:{
        primaryTag:{
            type: String,
            required: false,
            default: "Content"
        },
        secondaryTag:{
            type: String,
            required: false,
            default: "Layout"
        }, 
        actionHandler:{
            type: Function, 
            required: false, 
        }, 
        initialState:{
            type: Boolean, 
            required: false,
        }
    }, 
    data: function(){
        return{
            enabled: false
        }
    },
    methods: {
        toggle: function() {
            this.enabled = !this.enabled

            if(this.actionHandler)
                this.actionHandler(this.enabled)
        },
    }, 
    computed:{
        activeTag(){
            return this.enabled ? this.primaryTag : this.secondaryTag
        },
        inactiveTag(){
            return this.enabled ? this.secondaryTag :this.primaryTag 
        }
    }, 
    created(){
        this.enabled = this.initialState
             ?  this.initialState : false
    }

}
</script>
<style lang="less" scoped>

.switch-button-control{
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-self: center;

  .switch-button {
    @height: 32px;
    @switch-button-color: #3A4DFD;
    @switch-button-border-thickness: 2px;
    @switch-transition: all 0.5s ease-in-out;
    @switch-is-rounded: true;

    height: @height;
    width: 200px;
    border: 2px solid @switch-button-color;
    border-radius: if(@switch-is-rounded, @height, 0);
    transition: @switch-transition;

    @button-side-length: 99px;
  
    cursor: pointer;

    span{
        text-transform: uppercase;
    }

    .button {
        height: @height;
        width: @button-side-length;
        border: @switch-button-border-thickness solid @switch-button-color;
        border-radius: if(@switch-is-rounded, @button-side-length, 0);
        background: @switch-button-color;
        margin-top: -1px;
        margin-left: -1px;
        transition: @switch-transition;
        span{
            color: #FFFFFF;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            text-align: center;
            height: 16px;
            margin: auto;
        }
    }

    .inactive-tag{
        transition: @switch-transition;
        height: 16px;
        max-width: 85px;
        color: #3A4DFD;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 16px;
        text-align: center;
        position: absolute;
        top: 11px;
        right: 15px;
    }

    &.enabled {
      .button {
        transform: translateX(
          calc(@button-side-length)
        );
      }
      .inactive-tag{
          right: 0;
          left: 15px;
      }
    }
  }


}

</style>


