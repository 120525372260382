<template>
    <header class="row">
        <BreadCrumb/>
        <logo class="centered"/>
        <div class="profile-section row">
            <section class="row">
                <img class="information-icon-style" v-if="informationIcon && displayContributorPageIcon" :src="informationIcon" @click="goToContributorsPage">
                <img v-if="helpIcon" :src="helpIcon" @click="showNotifications" ref="notificationsButton">
                <transition name="fade">
                <i v-if="showNotificationsBox"></i>
                </transition>
                <transition name="fade">
                        <NotificationsBox  v-closable="{
                            exclude: ['notificationsButton'],
                            handler: 'onCloseNotificationsBox'
                            }" v-if="showNotificationsBox"/>
                </transition>
                <transition name="fade">
                    <span class="btn round failed" v-if="!showNotificationsBox && numberOfNotifications >0">{{numberOfNotifications}}</span>
                </transition>
            </section>
            <img v-if="avatar" :src="avatar" v-on:click="showProfileData">
            <span class="user-name">{{user}}</span>
            <div class="logout" @click="logOut()">{{$t('logout')}}</div>
        </div>
        <div class="command-section flex-container">
            <template v-if="isTeacherPreviewMode" >
                <button class="preview btn secondary" @click="goBack">{{$t('back')}}</button>
            </template>
            <template  v-if="isBackOfficeSection && showPreviewModeOptions && !isTeacherPreviewMode">
                <button v-if="!isPreviewModeActive" class="preview  btn secondary" @click="changeMode">{{$t('preview')}}</button>
                <template v-else>
                    <button class="preview btn secondary" @click="changeMode">{{$t('keepEditing')}}</button>
                    <span class="preview  btn secondary" @click="showModal = true">{{$t('publish')}}</span>
                    <modal v-if="showModal" :click-handler="publish"
                    text-button="publish"  @close="showModal = false">
                        <div class="message">{{$t('publishChangesQuestion')}}</div>
                    </modal>
                </template>
            </template>
        </div>
        <modal v-if="showProfileDataModal" 
                :clickOutSideModalHandler="hideModal"
                :showButtons="false">
                <profile-data v-on:close="hideModal"/>
        </modal>
    </header>  
</template>
<script>

import Logo from '@/common/Logo.vue'
import Modal from '@/common/Modal.vue'
import BreadCrumb from '@/common/BreadCrumb.vue'
import AuthenticationService from "@/services/AuthenticationService.js"
import NotificationsBox from '@/common/NotificationsBox.vue'
import ProfileData from "@/views/ProfileData.vue";
import { Role } from '@/utils/Roles.js'
export default {
    name : 'Navbar',
    components:{
        Logo ,
        BreadCrumb, 
        Modal, 
        NotificationsBox,
        ProfileData
    },
    data(){
        return{
            showModal: false, 
            showNotificationsBox: false,
            showProfileDataModal: false
        }
    },
    computed:{
        informationIcon(){
            return require('@/assets/icons/ic_info.svg')
        }, 
        helpIcon(){
            return require('@/assets/icons/ic_notification.svg')
        }, 
        avatar(){
            return require('@/assets/icons/ic_avatar.svg') 
        }, 
        isPreviewModeActive(){
            return this.$store.state.isPreviewModeActive
        }, 
        showPreviewModeOptions(){
         return this.$store.state.showPreviewModeOptions   
        },
        isBackOfficeSection(){
            return this.$route.meta.isBackOfficeSection
        }, 
        user(){
            let user = AuthenticationService.getProfile()
            return user ? `${user.firstName} ${user.lastName}` : ''
        }, 
        numberOfNotifications(){
            return this.$store.state.notificationsBox.filter(e => !e.isRead).length
        }, 
        isTeacherPreviewMode(){
            return this.isPreviewModeActive 
                && (Role.DistrictTeacher.name === this.profile.roleName 
                    || Role.CampusManager.name === this.profile.roleName)
        }, 
        profile() {
            return AuthenticationService.getProfile()
        }, 
        displayContributorPageIcon() {
            return this.profile && this.profile.roleName != Role.Student.name
        }
    }, 
    methods:{
        showNotifications(){
            this.$store.dispatch('loadNotifications')
            this.showNotificationsBox = !this.showNotificationsBox
        },
        goToContributorsPage(){
            this.$router.push({
                name: 'ContributorsPage'
            })
        },
        changeMode(){
            this.isPreviewModeActive 
                ? this.goToEditMode()
                : this.preview()
            this.$store.commit('updatePreviewModeStatus', !this.isPreviewModeActive)
        }, 
        goToEditMode(){
            let builderName = this.$route.name.replace('PreviewMode', '')
            this.$router.push({name: builderName, params:{
                ...this.$route.params
                }, 
                query:{
                ...this.$route.query
                }
            })
        },
        publish(){
            this.$store.commit('updateLoadingStatus', true)
            this['publish'+this.$store.state.builderName]()
                .then(()=>{
                    this.$store.commit('updatePreviewModeStatus', false)
                    this.$router.push({name: 'UnitDashboardBuilder'})
                })
                .finally(()=>{
                    this.$store.commit('updateLoadingStatus', false);
                })
            this.showModal = false
        },        
        publishLessonBuilder(){
           return this.$store.dispatch('publishLessonChanges', 
                this.$store.state.lessonBannerComponent.id)
        },
        publishUnitBuilder(){
           return this.$store.dispatch('publishUnitChanges', 
                this.$store.state.unitBuilderComponent.id)
        },
        publishOverviewBuilder(){
          return this.$store.dispatch('publishUnitChanges', 
                this.$store.state.unitOverviewBuilderComponent.unitId)
        },
        publishUnitDashboardBuilder(){            
          return this.$store.dispatch('publishUnits')
        },
        publishBiographyBuilder(){
          return this.$store.dispatch('publishBiography',
             this.$store.state.BiographyBuilderComponent)
        },
        // Publish Assessment will be performed by calling same endpoint 
        // for lesson as they belong together
        publishAssessmentBuilder(){
            return this.$store.dispatch('publishLessonChanges', 
                this.$route.params.lessonId)
        },
        publishTeacherPageBuilder(){
            return this.$store.dispatch('publishTeacherPageComponent', 
                this.$route.params.teacherPageId)
        },
        publishTimeLineBuilder(){
            return this.$store.dispatch('publishTimeLine', 
                this.$store.state.timeline.id)
        },
        publishContributorsPageBuilder(){
            return this.$store.dispatch('publishContributorsPageComponent')
        },
        preview(){
            let routeName =`${this.$store.state.builderName}PreviewMode`
            this.$router.push({name: routeName, 
            params:{
                ...this.$route.params
            }, 
            query:{
                ...this.$route.query
            }
            })
        }, 
        logOut(){
            AuthenticationService.logOut();
        }, 
        onCloseNotificationsBox(){
            this.showNotificationsBox = false
        },
        showProfileData(){
            this.showProfileDataModal = true;
        },
        hideModal(){
            this.showProfileDataModal = false;
        }, 
        goBack(){
            this.$store.commit('updatePreviewModeStatus', !this.isPreviewModeActive)
            let route = this.$route.query.studentId
                ? 'StudentReports'
                : 'ClassRoomDetails'
            this.$router.push({
                name: route,
                params:{ ...this.$route.query},
                query:{
                    ...this.$route.query, 
                    program: true
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
header{
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(58,77,253,0.20);
    height: 56px;
    z-index: 1;

    .th-logo{
        top: 24px;
    }
    .command-section{
        margin-right: 40px;
        align-self: center;
        span{
            text-transform: uppercase;
            align-self: center;
        }
        span:nth-child(2){
            margin-left:8px;
            height: 32px;
            width: 88px;
            border-radius: 2px;
            background-color: #3A4DFD;
            color: #fff;

            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 32px;
            text-align: center;
        }


        .preview{
            grid-area: logout-section;
            color: #3A4DFD;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            text-transform: uppercase;
            margin-top: 5px;
            cursor: pointer;
        }
    }
    .message{
        margin: 17px 16px;
        min-height: 48px;
        width: 232px;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 48px;
        text-transform: initial;
    }
    .profile-section{
        margin-left: auto;
        margin-right: 24px;
        align-self: center;
        >*{
            cursor: pointer;
            align-self: center;
            margin-left: 12px;
        }
        section:first-of-type{
            position: relative;
            >.btn{
                max-width: 20px;
                min-width: 20px;
                max-height: 20px;
                min-height: 20px;
                line-height: 22px;
                font-size: 12px;
                position: absolute;
                right: -15px;
                top: 0px;
                font-weight: 700;
                color: #fff;
            }
            i{
                border-radius: 4px;
                position: absolute;
                width: 16px;
                height: 16px;
                border-left: solid 1px #CFCFCF;
                border-top: solid 1px #CFCFCF;
                background-color: #fff;
                -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                box-shadow:  -3px -2px 2px -1px rgba(0, 0, 0, 0.2);
                top: 37px;
                right: 0px;
                z-index: 2;
            }
            img{
                max-width: 40px;
                max-height: 40px;
                margin-right: 24px;
            }
            .information-icon-style{
                margin-right: 0;
            }
        }
        img{
            margin-top: 6px;
        }
        i{
            position: relative;
        }
        .user-name{
            color: rgba(0,0,0,0.60);
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            text-transform: uppercase;
            margin-top: 5px;
            max-width: 250px;
        }
        .logout{
            color: #3A4DFD;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            text-transform: uppercase;
            margin-top: 5px;
            cursor: pointer;
        }
    }
}

</style>

