 // eslint-disable-next-line
require('./assets/styles.css')
require('./assets/animations.css')
require('./assets/icons.less')
require('./assets/buttons.less')
require('./assets/BackOfficeStyles.less')
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/css/index.css';
import {i18n} from './plugins/i18n.js';
import VueYouTubeEmbed from 'vue-youtube-embed'
import GlobalMixin from './GlobalMixin.js'
import { Role } from '@/utils/Roles.js'
import AuthenticationService from "@/services/AuthenticationService.js"
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@/utils/ClosableDirective.js'
import AppInsights from "@/AppInsights.js";

Vue.use( CKEditor )
Vue.use(Tooltip, {
  delay: 100,
  placement: 'right',
  class: 'th-tooltip',
  triggers: ['hover', 'focus'],
  offset: 0
});

Vue.use(VueYouTubeEmbed, { global: true, componentId: "youtube-media" })

Vue.config.productionTip = false

router.afterEach((to) => {
  store.commit('updateCurrentView', to.name)
})

router.beforeEach((to, from, next) => {
  let authorize  = to.meta.authorize;
  // if view does not require authorization
  if(!to.matched.some(record => record.meta.requiresAuth)) {
      next() 
      return 
  }
  // if user is not authenticated
  if (!sessionStorage.getItem('auth-token')){
    next({ path: '/signin', query: { returnUrl: to.path } }) 
    return 
  }
  // If user does not have access to view
  let user = AuthenticationService.getProfile()
  let userHome = Role[user.roleName.replace(' ', '')].home
  if (authorize.length && !authorize.includes(user.roleName)) {
    next({name: userHome})
    return
  }
  next()
})

Vue.use(AppInsights);

Vue.mixin(GlobalMixin)

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
})