export default {
    contributorsPage: '',
    serviceDeskWidget: false,
    contributorsPageComponent: '',
    // Content
    units: null,
    snackBarNotifications: [],
    notificationsBox:[],
    loadingStatus: false,
    showPreviewModeOptions: true, 
    audioPlaying: '',
    videoPlaying: '',
    // NAVIGATION RELATED STATE
    breadcrumbElements: [{text:'UNIT 1', route:'/'}], 
    currentView: '',
    // UNIT OVERVIEW AND LESSON DASHBOARD RELATED STATE
    overview: null, 
    currentBlurb: null, 
    currentUnit: null,
    teacherPage: null,
    currentLesson: null, 
    currentBiography: '',
    // ASSESSMENT RELATED STATE
    assessment: '',
    currentAssessmentSection: null,
    // ######### BACK OFFICE STATE SECTION ######### 
    builderName: '',
    currentBackOfficeComponent: null,
    // LesssonBuilder
    isPreviewModeActive: false,
    isSwitchButtonDisplayed: true,
    isContentSectionActive: false,    
    LessonBuilderComponents: [], 
    lessonBannerComponent : '',
    //UnitBuilder
    unitBuilderComponent: null, 
    //UnitOverviewBuilder
    unitOverviewBuilderComponent: '', 
    
    //UnitDashboardBuilder
    UnitDashboardComponents: [],
    lastUnitDashboardComponentExpanded: '',
    //Biography Builder
    BiographyBuilderComponent: '', 
    //Vocabulary Manager
    vocabularyComponents: [],
    //Assessment Builder State
    assessmentHeader: '',
    assessmentQuestions: '',
    // Time Line Builder State
    timeLineBuilderComponent: '',
    // Time Line (Student View) state
    timeline: '',
    
    timeLineEvents: '', 
    currentTimeLineEvent: '',
    isTimeLineEventCardDisplayed: false, 

    teacherPageComponent: '', 

    //Classroom management
    classrooms: [], 
    selectedClassroom: '',
    newClassRoomSelectedTeacher: null,
    newClassroom:{
        name: '', 
        file: ''
    }, 
    newStudent: {
        username: '', 
        password: '',
        firstName: '',
        middleName: '',
        lastName: '',
        secondLastName: '',
        birthDate: '', 
        classRoomId: ''
    },
    dealerPromise: null,
    selectedStudentForFeedBack: '',
    studentContentForFeedBack: [],
    authenticationForm: 'sign-in', 
    
    selectedUnitForFeedback:'',
    selectedStudent: '',

    teacherGroups: {},
    studentPerformance: null,
    studentReportsCurrentSection: null,
    classRoomSubNavBarWithSectionSelect: false,
    classroomDetailsSection: '',
    currentClassroomManagementSection: '',
    classroomPerformance: [],

    //Users Management
    usersCollection: {},
    schools: [],
    districts: [],

    //Management Dashboard
    currentManagementSection: "",
    analyzedManagementSection: "",
    managementDashboardNavBarPath: {},

    managementObj: {
        currentState: null,
        states: [],
        districts: [],
        telemetries: []
    }, 
    vennDiagramElements:[], 
    standardsReport: '', 

    programPreview: [],
    unitPreviewExpanded: null
}