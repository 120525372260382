<template>
  <div id="app">
    <section class="headers-section">
      <component :is="header"/>
      <component v-if="navbar" :is="navbar"/>
    </section>
    <router-view />
    <notifications-container/>
    <div class="loader-container" v-if="loading">
        <bounce-loader class="loader" :loading="loading"
         color="#3A4DFD" size="60px"></bounce-loader>
    </div>
    <div class="unresponsive-legend">
      <span>
        {{$t('screenSizeNotSupportedMessage')}}
      </span>
    </div>
    <modal v-if="showIdleStateModal" :click-handler="clearIdleTimeOut"
      text-button="Ok"  @close="logOut">
      <div class="idle-message">{{$t('idleStateAlert')}}</div>
    </modal>
  </div>
</template>
<script>
import Navbar from '@/common/Navbar.vue'
import UnitMenuBar from '@/common/UnitMenuBar.vue'
import ClassRoomSubNavbar from '@/common/ClassRoomSubNavbar.vue'
import SnackBarNotificationsContainer from '@/common/SnackBarNotificationsContainer.vue'
import BuilderBar from '@/common/BuilderBar.vue'
import ManagementDashboardBar from '@/common/ManagementDashboardBar.vue';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import AuthBar from '@/common/AuthBar.vue'
import AuthenticationService from "@/services/AuthenticationService.js"
import { Role } from '@/utils/Roles.js'
import { setTimeout, clearTimeout } from 'timers';
import Modal from '@/common/Modal.vue'
import NotificationsService from "@/services/NotificationsService.js"
export default {
  components:{
    'nav-bar': Navbar, 
    'unit-menu-bar': UnitMenuBar, 
    'notifications-container': SnackBarNotificationsContainer, 
    'builder-bar': BuilderBar, 
    'bounce-loader': BounceLoader,
    'auth-bar': AuthBar, 
    'classroom-nav-bar':ClassRoomSubNavbar, 
    'management-dashboard-nav-bar': ManagementDashboardBar, 
    Modal
  }, 
  data(){
    return {
      timeOutIdle: '', 
      timeOutAlert: '',
      showIdleStateModal: false,
      intervalForTimeOutIdleAlert: 3540000, 
      intervalForIdleTimeOut: 3600000
    }
  },
  computed:{
    header(){
      return this.$route.meta.public ? 'auth-bar' : 'nav-bar';
    }, 
    loading(){
      return  this.$store.state.loadingStatus
    }, 
    navbar(){
      return this.$route.meta.subnavbar
    }
  }, 
  methods:{
    clearIdleTimeOut(){
      if(!this.timeOutIdle)
        return
      this.showIdleStateModal= false
      clearTimeout(this.timeOutIdle)
      this.timeOutIdle = setTimeout(this.logOut, this.intervalForIdleTimeOut)
      clearTimeout(this.timeOutAlert)
      this.timeOutAlert = setTimeout(()=>{
        if(!sessionStorage.getItem("auth-token"))
          return
        this.showIdleStateModal = true
      }, this.intervalForTimeOutIdleAlert)
    }, 
    logOut(){
      if(!sessionStorage.getItem("auth-token"))
          return
      this.showIdleStateModal= false
      AuthenticationService.logOut()
      clearTimeout(this.timeOutIdle)
      this.timeOutIdle = null
    },
    startNotificationsConnection(){
      if(!sessionStorage.getItem("auth-token"))
        return;
      
      NotificationsService.setNotificationsHandler({
				messageType: "ReceiveMessage",
				handler: () => this.$store.dispatch('loadNotifications')
			});

      let _user = AuthenticationService.getProfile();

      if(_user.roleId == Role.ContentCreator.roleId){
        
        let _failedMessage = this.$t("FailedToPublishMessage");
        let _publishingMessage = this.$t("publishingMessage");
        let _sucessfullPublishingMessage = this.$t("sucessfullPublishingMessage");

        NotificationsService.setNotificationsHandler({
            messageType: "Publishing",
            handler: message => {
                  if(message.isPublishing){
                    if(message.publisherId != _user.id){
                      this.$store.commit('addSnackBarNotification', {
                        message: _publishingMessage, 
                        dismissable: false
                      });

                      if(this.$route.name === "UnitDashboardBuilder")
                        this.$store.commit('loadUnitDashboardComponents', []);
                      else
                        this.$router.push({ name: "UnitDashboardBuilder" });
                    }
                  }
                  else if(message.hasError){
                    this.$store.dispatch("loadUnitDashboardComponents");
                    this.$store.commit('addSnackBarNotification', {
                      message: _failedMessage, 
                      dismissable: false
                    });
                  }
                  else{
                    this.$store.commit("sortUnitDashboardComponents");
                    this.$store.commit('addSnackBarNotification', {
                      message: _sucessfullPublishingMessage,
                      dismissable: false
                    });
                  }
                }
          });

        NotificationsService.setNotificationsHandler({
            messageType: "UnitPublished",
            handler: message => {
                  if(this.$route.name === "UnitDashboardBuilder")
                    this.$store.commit("createUnitDashboardComponent", message.unit);
                }
          });
      }
      NotificationsService.startNotificationsHubConnection()
      this.$store.dispatch('loadNotifications')
    },
  },
  created(){
    this.startNotificationsConnection()
    let language = sessionStorage.getItem('language')
    if(language){
      this.$store.commit('updateLanguage', language)
    }
    document.addEventListener('click', this.clearIdleTimeOut)
    this.timeOutIdle = setTimeout(this.logOut, this.intervalForIdleTimeOut)
    this.timeOutAlert = setTimeout(()=>{
      if(!sessionStorage.getItem("auth-token"))
          return
      this.showIdleStateModal = true
    }, this.intervalForTimeOutIdleAlert)
    
    screen.orientation.lock('landscape')
      // eslint-disable-next-line no-console
      .then((e)=> console.log('screen orientation updated:',e))
      .catch(()=>{
        // eslint-disable-next-line no-console
        console.log('unable to lock screen orientation as landscape')
      })
  }, 
  mounted(){
    let hotjarId = parseInt(process.env.VUE_APP_HOTJAR_ID);
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid: hotjarId,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }
}
</script>
<style lang="less">

#app .idle-message{
    width: 320px;
    height: 120px;
    margin-top: 16px;
    text-align: center;
}

.headers-section{
  position:fixed ;
  top: 0;
  z-index: 3;
  width: 100%;
}

.headers-section header:first-child{
  z-index: 4;
}

.headers-section header:nth-child(2){
  z-index: -1;
}
.loader-container, .unresponsive-legend{
    min-height: 100%;
    min-width: 100%;
    z-index: 11;
    background: rgba(255, 255, 255,0.3);
    position: fixed;
    top: 58px;
    left: 0;
}

.unresponsive-legend{
  background: rgba(255, 255, 255, 1);
  display: none;
  span{
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
     transform: translate(-50%, -50%); 
  }
}

.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* @media screen and (max-width: 1023px){
  .unresponsive-legend{
    display: initial;
  }
} */


</style>

