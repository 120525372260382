import { render, staticRenderFns } from "./SnackBarNotificationsContainer.vue?vue&type=template&id=1605df81&scoped=true&"
import script from "./SnackBarNotificationsContainer.vue?vue&type=script&lang=js&"
export * from "./SnackBarNotificationsContainer.vue?vue&type=script&lang=js&"
import style0 from "./SnackBarNotificationsContainer.vue?vue&type=style&index=0&id=1605df81&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1605df81",
  null
  
)

component.options.__file = "SnackBarNotificationsContainer.vue"
export default component.exports