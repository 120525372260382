import { Role } from '@/utils/Roles.js'
export default{
    routes :[
        {
            path: '/',
            name: 'Home',
            component: () => import('@/views/UnitsDashboard.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'studentHomeBreadCrumb'
            }
          },
          {
            path: '/unit/:unitId',
            name: 'Unit', 
            component: () => import('@/views/LessonsDashboard.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'studentHomeBreadCrumb', 
              subnavbar: 'unit-menu-bar'              
            }
          },
          {
            path: '/overview/:unitId',
            name: 'Overview', 
            component: () => import('@/views/UnitOverview.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'studentLessonDashboardBreadCrumb', 
              subnavbar: 'unit-menu-bar'
            }
          },
          {
            path: '/teacher-page/unit/:unitId/teacher-page/:teacherPageId',
            name: 'TeacherPageUnit', 
            component: () => import('@/views/TeacherPage.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'studentLessonDashboardBreadCrumb', 
              subnavbar: 'unit-menu-bar'
            }
          },
          {
            path: '/teacher-page/unit/:unitId/lesson/:lessonId/teacher-page/:teacherPageId',
            name: 'TeacherPageLesson', 
            component: () => import('@/views/TeacherPage.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'studentLessonDashboardBreadCrumb', 
              subnavbar: 'unit-menu-bar'
            }
          },
          {
            path: '/unit/:unitId/biography/:biographyId',
            name: 'Biographies', 
            component: () => import('@/views/Biography.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'studentLessonBreadCrumb', 
              subnavbar: 'unit-menu-bar'
            }
          },
          {
            path: '/unit/:unitId/lesson/:lessonId',
            name: 'Lesson', 
            component: () => import('@/views/LessonLayout.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'studentLessonBreadCrumb', 
              subnavbar: 'unit-menu-bar'
            }
          }, 
          {
            path: '/unit/:unitId/lesson/:lessonId/assessment',
            name: 'Assessment', 
            component: () => import('@/views/Assessment.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'studentLessonBreadCrumb', 
              subnavbar: 'unit-menu-bar'
            }
          }, 
          {
            path: '/unit/:unitId/time-line',
            name: 'TimeLine', 
            component: () => import('@/views/TimeLine.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'timelineBreadCrumb', 
              subnavbar: 'unit-menu-bar'
            }
          }, 
          {
            path: '/unit/:unitId/videos',
            name: 'VideoGallery', 
            component: () => import('@/views/VideoGallery.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [Role.Student.name], 
              breadcrumb: 'studentLessonBreadCrumb', 
              subnavbar: 'unit-menu-bar'
            }
          },
    ]
}