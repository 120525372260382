<template>
    <div class="th-breadcrumb row" v-if="breadCrumbElements">
        <back-button v-for="(element, index) in breadCrumbElements" :key="index"
         :text="element.text" :route="element.route" />
    </div>
</template>
<script>
import BackButton from '@/common/BackButton.vue'

export default {
    name: 'BreadCrumb',
    components:{
        BackButton
    },
    computed: {
        unitNumber(){
            if(!this.$store.state.currentUnit)
                return ''
            return this.$store.state.currentUnit.number
        },
        lessonNumber(){
            if(!this.$store.state.currentLesson)
                return ''
            return this.$store.state.currentLesson.number
        },
        breadCrumbElements(){
            return this[this.$route.meta.breadcrumb] || []
        },
   
        // Standards breadcrumbs used all over the application
        // these are referenced in routes files.
        unitBuilderBreadcrumb(){
            return [{ 
                text:this.$t('exit'),
                route:{
                    name: 'UnitBuilder', 
                    params: {
                        unitId: this.$route.params.unitId  
                    }
                }}]
        }, 
        defaultBackOfficeBreadCrumb(){
            return [{text:this.$t('exit'), route:{
                name: 'UnitDashboardBuilder', 
                query:{
                    selected: this.$route.params.unitId
                }
             }}]
        }, 
        studentHomeBreadCrumb(){
            return [{text:this.$t('units'), 
                route:{name: 'Home'}
            }]
        }, 
        studentLessonDashboardBreadCrumb(){
            return[{text:`${this.$t('unit')} ${this.unitNumber}`, route: {name: 'Home'}},
                    {text: this.$t('overview') ,
                    route: {name: 'Unit', params:{
                        ...this.$route.params
                    }}}]
        }, 
        studentLessonBreadCrumb(){
            return [{text:`${this.$t('unit')} ${this.unitNumber}`, route:{name: 'Home'}},
                    {text:`${this.$t('lesson')} ${this.lessonNumber}`, 
                    route: {name: 'Unit', params:{
                        ...this.$route.params
                    }}}]
        }, 
        timelineBreadCrumb(){
            return [{text:`${this.$t('unit')} ${this.unitNumber}`,
                    route: {name: 'Unit', params:{
                        ...this.$route.params
                    }}}]
        },
        classroomBreadCrumb(){
             return [{text:this.$t('exit'), route:{
                 name:'ClassRoomManagement', 
                 query:{
                     ...this.$route.query
                 }
             }}]
        }
    }, 
    methods:{
        loadUnit(){
            if(!this.$route.params.unitId)
                return
            if(!this.unit)
                this.$store.dispatch('loadCurrentUnit', this.$route.params.unitId)
        },
        loadLesson(){
            if(!this.$route.params.lessonId)
                return
            if(!this.lesson)
                this.$store.dispatch('loadLesson', this.$route.params.lessonId)
        },

    },
    watch: {
        '$route': function(){
            this.loadUnit()
            this.loadLesson()
        }
    }
}
</script>
