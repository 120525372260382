import apiClient from '@/services/ApiClient.js'


export default {
    async get(lessonId) {
        return apiClient.get(`lesson/${lessonId}`)
    },
    async updateLessonOrder(lesson) {
        return apiClient.patch(`lesson/${lesson.id}`, {number: lesson.number})
    },
    async removeLesson(lessonId) {
        return apiClient.delete(`lesson/${lessonId}`)
    },
    async createLesson(unitId) {
        return apiClient.post('lesson', {unitId: unitId})
    },
    async updateLessonName(lesson) {
        return apiClient.patch(`lesson/${lesson.id}/change-name`, {name: lesson.name })
    },
    async setCompleted(lesson){
        return apiClient.patch(`lesson/${lesson.id}/lesson-completed`)
    }
}