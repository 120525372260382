<template>
    <header>
        <div class="auth-bar-icon column">
            <img class="map-image" :src="logoImage"/>
        </div>
    </header>
</template>

<script>
export default{
    name: "AuthBar",
    computed: {
        logoImage(){
            return require('@/assets/3 Header Logo.png')
        }
    }
}
</script>

<style lang="less" scoped>
header{
    height: 80px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.14), 0 2px 2px 0 rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);

    .auth-bar-icon{
        width: 150px;
        height: 100%;

        margin: 0 auto;
        img{
            margin-top: 20px
        }
        span{
            height: 23px;
            font-family: Athelas;
            font-weight: bold;
            line-height: 23px;
        }

        span:first-child{
            margin-top: 16px;

            color: #0A1554;
            font-size: 21px;
            letter-spacing: 0.73px;

            &::before{
                content: '★';
                color: #0A1554;
                margin-right: 3px;
            }
        }

        span:last-child{
            color: #CC1C1C;
            font-size: 20px;
            letter-spacing: 0.69px;
        }
    }
}
</style>
