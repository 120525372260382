const Factory ={
    CreateTextContent(){
        this.id = 0
        this.en = ''
        this.es = ''
    }, 
    CreateAudio(){
        this.id = 0
        this.en = ''
        this.es = ''
    }, 
    CreateParagraph(order, templateProperties){
        this.order = order
        this.title = new Factory.CreateTextContent()
        this.content = new Factory.CreateTextContent()
        this.videoUrl= new Factory.CreateTextContent()
        this.background =''
        this.opacity =100
        this.size =12
        this.alignment ='flex-start'
        this.orientation = 'left-side'
        this.height= 'auto'
        this.images=[]
        this.audio= new Factory.CreateAudio()
        this.errors=[]
        this.routePoints=[]
        this.isProgressiveMap= false
        this.isHorizontalMode= false
        this.essentialQuestion = new Factory.CreateEssentialQuestion()
        this.displayStepNumbers = true

        for(let property of Object.keys(templateProperties)){
            this[property] = templateProperties[property]
        }
    }, 
    CreateEssentialQuestion(){
        this.id = null
        this.question = new Factory.CreateTextContent()
        this.audio = new Factory.CreateAudio()
    },
    CreateTimeLineEvent(id, setters){
        this.id = id
        this.date = new Factory.CreateTextContent()
        this.title = new Factory.CreateTextContent()
        this.shortDescription = new Factory.CreateTextContent()
        this.longDescription = new Factory.CreateTextContent()
        this.audio = new Factory.CreateAudio()
        this.imageId =0
        this.setters= setters
    }, 
    GetTimelineDirectionAudios(){
        this.id = 'timeline-directions'
        this.nameSpanish = process.env.VUE_APP_TIMELINE_ES_AUDIO
        this.nameEnglish = process.env.VUE_APP_TIMELINE_EN_AUDIO
    }
}

export default Factory;