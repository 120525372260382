<template>
    <li class="drop" @mouseleave="displayed = false">
        <a @click="clickHandler(menu)"  :class="active">{{menu.text}}</a>
        <ul v-if="menu.items && menu.items.length > 0" class="custom-scrollbar drop_menu" :class="{display: displayed}">
            <a v-for="(item,index) in menu.items" :key="index" @click="clickHandler(item)">
            {{item.title || item.name}}</a>
        </ul>
        <modal v-if="showModal" text-button="Ok" 
            :click-handler="hideVocabularyBox" 
            :clickOutSideModalHandler="hideVocabularyBox"
            :showCancelButton="showModalCancelButton"
            :modalType="modalType"> <vocabulary-box :vocabulary="selectedWord" /></modal>
    </li>
</template>
<script>
import Modal from '@/common/Modal.vue'
import VocabularyBox from '@/components/Lesson/VocabularyBox.vue'
export default {
  name: 'DropDown',
    components:{
        Modal, 
        VocabularyBox
    },
    data: function(){
        return{
            displayed: false,
            TextWithLinks:[], 
            selectedWord: '',
            showModal: false,
            showModalCancelButton: true,
            modalType: ""
        }
    }, 
    props:{
        menu : Object
    },
    methods:{
        clickHandler(item){
            if(item.route) 
            {
                this.$router.push(item.route)
                return
            }

            this.showVocabularyBox(item)

            this.displayed = item.items && item.items.length > 0;
        },
        showVocabularyBox(item){
            let word = this.vocabulary
                .find(e => e.name === item.title || e.name === item.name)
            if(!word)
                return
            this.selectedWord = word
            this.modalType = "vocabularyModal";
            this.showModalCancelButton = false;
            this.showModal = true
            this.showModalCancelButton = false;
        },
        hideVocabularyBox(){
            this.showModal = false
            this.showModalCancelButton = true; 
            this.modalType = "";
            this.selectedWord =''
        }
    }, 
    computed:{
        active(){
            return this.$store.state.currentView.toUpperCase() === this.menu.text.toUpperCase()
                ? 'drop-active' : 'drop-inactive' || ''
        }, 
        vocabulary(){
            return this.$store.state.currentUnit
                ? this.$store.state.currentUnit.vocabularies
                : []
        },
        
    }, 
}
</script>

<style lang="less" scoped>

.drop-active{
    color:#3A4DFD;
}

.drop-inactive{
    color: rgba(0,0,0,0.38);
}

.drop{
	position: relative;
    user-select: none;
    margin-left: 29px;
	a {
        white-space: nowrap;
		height: 14px;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 16px;
        text-align: initial;
        margin-left: 8px;
        text-transform: uppercase;
		
		cursor: pointer;
		border: none;
		border-bottom: 2px solid #fff;
		transition: 0.2s;
        background: none;
        
	}
}

.drop_menu {
	z-index: 2;
    overflow-y: auto;
	position: absolute;
    display: block;
    max-height: 50vh;
	width: auto;
	min-width: 160px;
	top: 100%;
    transform: scaleY(0);
    transform-origin: top;
    background-color: #fff;
	transition: 0.25s;
	padding-bottom: 10px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
    
	a {
		width: auto;
		min-width: 130px;
		margin-top: 10px;
		display: block;
		transition: 0.45s;
		opacity: 0;
        margin-right: 10px;
        color: rgba(0,0,0,0.38);    
	}

}

.drop_menu.display {
	transform: scaleY(1);
	a {
		opacity: 1;
	}
}

</style>