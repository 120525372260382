import apiClient from '@/services/ApiClient.js'

export default {
  async loadProgramPreview() {
    return apiClient.get('program/preview')
  },
  async getUnits() {
    return apiClient.get('units')
  },
  async getUnit(id) {
    return apiClient.get(`unit/${id}`)
  },
  async getOverview(unitId) {
    return apiClient.get(`unit/${unitId}/overview`)
  },
  async getOverviewComponents(unitId) {
    return apiClient.get(`unit/${unitId}/overview-backups`)
  },
  updateOverviewStatus(unitId){
    return apiClient.patch(`unit/${unitId}/overview`);
  },
  async getUnitSkills(unitId){
    return apiClient.get(`unit/${unitId}/unitskills`)
  },
  async createUnit(){
    return apiClient.post('unit')
  }

}